/* define transition times as variables */
:root {
  --fadeTransition: 1000ms;
}
.fade-appear {
  opacity: 0;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity var(--fadeTransition) ease-in;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity var(--fadeTransition) ease-in;
}
.fade-exit{
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity, var(--fadeTransition) ease-in;
}
